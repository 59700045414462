import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import Masonry from 'react-masonry-css';
import Lightbox from '../../../Lightbox';

import imageHelper from '../../../Image/helper';
import SectionMedia from '../SectionMedia';
import { getEmbedUrl } from '../../../../helper';

import styles from './styles.module.css';

class CustomLayout extends React.PureComponent {

  constructor(props) {

    const images = [];
    if (props.section) {

      props.section.data.forEach(element => {

        if (element.type === 'GALLERY' && element.lightbox === true) {

          element.data.forEach(img => {

            images.push(img);

          });

        }

      });

    }

    super(props);

    this.state = {
      section: props.section,
      color0: props.themeData.colors[0],
      images,
      photoIndex: 0,
      isOpen: false,
    };

    this.createText = this.createText.bind(this);
    this.createImage = this.createImage.bind(this);
    this.createVideo = this.createVideo.bind(this);
    this.createGallery = this.createGallery.bind(this);
    this.createQuote = this.createQuote.bind(this);
    this.handleImageClick = this.handleImageClick.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleMoveNextRequest = this.handleMoveNextRequest.bind(this);
    this.handleMovePrevRequest = this.handleMovePrevRequest.bind(this);

  }

  createText(item, index, quote) {

    const cols = quote === true ? '' : 'col-10 col-lg-8';
    const id = `${this.state.section._id}_P_${index}_section`;
    let content;
    let styleNames;

    if (item.type && item.type === 'HEADINGS/HEADING-ONE' && item.active) {

      content = <h1 className={styles.title}>{ HTMLParser(item.text) }</h1>;
      styleNames = styles.customText;

    } else if (item.type && item.type === 'HEADINGS/HEADING-TWO') {

      content = (
        <h2
          className={styles[`Title3${this.props.themeData.typography.heading.fontSize}`]}
        >
          { HTMLParser(item.text) }
        </h2>
      );
      styleNames = styles.customText;

    } else if (item.type && item.type.startsWith('PARAGRAPH')) {

      content = <span>{ HTMLParser(item.text) }</span>;
      styleNames = quote === true ? undefined : styles.customText;

    }

    const elem = (
      <div
        key={id}
        className={`${cols} ${styleNames}`}
      >
        { content }
      </div>
    );

    return elem;

  }

  createImage(item, index, subIndex, cols, normal, lightbox) {

    let imgWrapper = 'imageContent5';
    let img = 'imageFull';
    if (normal === true) {

      imgWrapper = 'imageWrapper100';
      img = 'galleryImg';

    }
    const sizes = cols === undefined ? '100vw' : `(max-width: 576px) 100vw, (min-width: 576px and max-width: 768px) ${cols === 1 ? 100 : 50}vw, (min-width: 768px) ${100 / cols}vw`;

    const elem = (
      <div
        className={subIndex !== undefined ? undefined : 'col-10'}
        key={`${this.state.section._id}_Image_${subIndex !== undefined ? subIndex : ''}${subIndex !== undefined ? subIndex : ''}${index}_section`}
      >
        <div className={styles.customImageWrapper} style={lightbox ? { cursor: 'pointer' } : undefined}>
          <SectionMedia
            mediaType={item.icon ? 'ICON' : 'IMAGE'}
            wrapperStyle={imgWrapper}
            elementStyle={img}
            iconStyle=""
            sizes={sizes}
            onClick={lightbox ? this.handleImageClick : undefined}
            src={item.CDNLink
              ? item.CDNLink
              : `${process.env.IMAGES_CDN}/${item.src}`}
            alt={item.alt}
            data={item}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            colors={this.props.themeData.colors}
          />
        </div>
      </div>
    );

    return elem;

  }

  createVideo(item, index) {

    let videoUrl;
    let video;
    if (item.videoID) videoUrl = getEmbedUrl(item);

    if (videoUrl) {

      video = (
        <div className="col-10" key={`${this.state.section._id}_Custom_Preview_${index}_Video`}>
          <iframe
            frameBorder="0"
            className={styles.videoIframeStyle}
            src={videoUrl}
            allowFullScreen
            aria-hidden="true"
          />
        </div>
      );

    }

    return video;

  }

  createGallery(items, index, lightbox) {

    const pictures = [];
    items.forEach((item, i) => (

      pictures.push(this.createImage(
        item,
        index,
        i,
        Number(this.state.section.data[index].columns),
        this.state.section.data[index].crop,
        lightbox,
      ))

    ));

    let gallery;
    const md = this.state.section.data[index].columns
    && Number(this.state.section.data[index].columns) === 1 ? 1 : 2;

    if (this.state.section.data[index].crop !== true) {

      const breakpointColumnsObj = {
        default: Number(this.state.section.data[index].columns),
        768: md,
        576: 1,
      };

      gallery = (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          style={{ display: 'flex', width: '100%' }}
          className=""
          columnClassName=""
        >
          { pictures.map((pic, i) => <div key={`${this.state.section._id}_Gallery_Image${index}${i}`} className={styles.masonryImageWrapper}>{pic}</div>) }
        </Masonry>
      );

    } else {

      gallery = [];
      const cols = `col-12 col-sm-${12 / md} col-md-${12 / Number(this.state.section.data[index].columns)}`;
      pictures.forEach((p, i) => {

        const pic = (
          <div
            className={cols}
            key={`${this.state.section._id}_Gallery_Image${index}${i}`}
          >
            {p}
          </div>
        );

        return gallery.push(pic);

      });

    }

    const result = (
      <div key={`${this.state.section._id}_Gallery_${index}`} className="col-10">
        <div className="row">
          { gallery }
        </div>
      </div>
    );

    return result;

  }

  createQuote(item, index) {

    const quote = (
      <div className={`col-8 ${styles.quoteWrapper}`} key={`${this.state.section._id}_Custom_${index}_Quote`}>
        <div className={styles.quoteBar} style={{ backgroundColor: this.state.color0 }} />
        <div>
          <div style={{ height: '10px' }} />
          { this.createText(item, index, true) }
          <div style={{ height: '10px' }} />
        </div>
      </div>
    );

    return quote;

  }

  handleImageClick(event) {

    const photoIndex = this.state.images
      .findIndex(image => image.id === event.target.dataset.imageid);

    if (photoIndex > -1) {

      this.setState({
        photoIndex,
        isOpen: true,
      });

    }

  }

  handleCloseClick() {

    this.setState({
      isOpen: false,
    });

  }

  handleMoveNextRequest() {

    this.setState({
      photoIndex: (this.state.photoIndex + 1) % this.state.images.length,
    });

  }

  handleMovePrevRequest() {

    this.setState({
      photoIndex: (this.state.photoIndex + (this.state.images.length - 1))
        % this.state.images.length,
    });

  }

  render() {

    const content = [];
    if (this.state.section.data) {

      this.state.section.data.forEach((item, index) => {

        let elem;
        if (item.type === 'HEADINGS/HEADING-ONE' || item.type === 'HEADINGS/HEADING-TWO' || item.type === 'PARAGRAPH/PARAGRAPH') {

          elem = this.createText(item, index);

        } else if (item.type === 'PARAGRAPH/QUOTE') {

          elem = this.createQuote(item, index);

        } else if (item.type === 'IMAGE') {

          elem = this.createImage(item.content, index);

        } else if (item.type === 'VIDEO') {

          elem = this.createVideo(item, index);

        } else if (item.type === 'GALLERY') {

          elem = this.createGallery(item.data, index, item.lightbox);

        }

        content.push(elem);

      });

    }

    return (
      <div className="container">
        {
          this.state.images.length > 0
          && (
            <Lightbox
              mainSrc={imageHelper.getBestQuality(
                this.state.images[this.state.photoIndex].id, this.props.images,
              )}
              nextSrc={imageHelper.getBestQuality(
                this.state.images[(this.state.photoIndex + 1) % this.state.images.length].id,
                this.props.images,
              )}
              prevSrc={imageHelper.getBestQuality(
                this.state.images[
                  (this.state.photoIndex + (this.state.images.length - 1))
                  % this.state.images.length].id,
                this.props.images,
              )}
              onCloseRequest={this.handleCloseClick}
              onMovePrevRequest={this.handleMovePrevRequest}
              onMoveNextRequest={this.handleMoveNextRequest}
              visible={this.state.isOpen}
            />
          )
        }
        <div className={`row ${styles.customRow}`}>
          { content }
        </div>
      </div>
    );

  }

}

CustomLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  themeData: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CustomLayout;
